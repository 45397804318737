@mixin menu {
  $shadow-color: opacify($background, .8);
  $shadow: 0 10px $shadow-color, -10px 10px $shadow-color, 10px 10px $shadow-color;
  position: absolute;
  background: $background;
  box-shadow: $shadow;
  color: white;
  border: 2px solid;
  margin: 0;
  padding: 10px;
  list-style: none;
  z-index: 99;
}

.header {
  display: flex;
  flex-direction: column;
  position: relative;

  @media print {
    display: none;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: flex;
    flex: 1;

    &:after {
      content: '';
      background: repeating-linear-gradient(90deg, $accent, $accent 2px, transparent 0, transparent 10px);
      display: block;
      width: 100%;
      right: 10px;
    }

    a {
      flex: 0 0 auto;
      max-width: 100%;
      text-decoration: none;
    }
  }

  .menu {
    display: flex;
    align-items: flex-start;
    margin: 20px 1px;

    @media ($phone) {
      @include menu;
      top: 50px;
      right: 0;

      &.hidden-on-mobile {
        display: none;
      }
    }

    &__inner {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;

      &--desktop {
        @media ($phone) {
          display: none;
        }
      }

      &--mobile {
        display: none;

        @media ($phone) {
          display: block;
        }
      }

      li {
        &:not(:last-of-type) {
          margin-right: 20px;
          margin-bottom: 10px;
          flex: 0 0 auto;
        }
      }

      @media ($phone) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;

        li {
          margin: 0;
          padding: 5px;
        }
      }
    }

    &__sub-inner {
      position: relative;
      list-style: none;
      padding: 0;
      margin: 0;

      &:not(:only-child) {
        margin-left: 20px;
      }

      &-more {
        @include menu;
        top: 0;
        left: 0;

        &-trigger {
          color: $accent;
          user-select: none;
          cursor: pointer;
        }

        li {
          margin: 0;
          padding: 5px;
          white-space: nowrap;
        }
      }
    }

    .spacer {
      flex-grow: 1 !important;
    }

    .language-selector {
      position: relative;
      list-style: none;
      margin: 0;

      &-current {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        color: $accent;
        cursor: pointer;
        margin-right: 0 !important;
      }

      &__more {
        @include menu;
        top: 0;
        right: 0;
      }
    }

    &-trigger {
      display: none;
      color: $accent;
      border: 2px solid;
      margin-left: 10px;
      height: 100%;
      padding: 3px 8px;
      position: relative;
      cursor: pointer;

      @media ($phone) {
        display: block;
      }
    }
  }
}
